body {
    font-family: "canada-type-gibson", sans-serif;
    font-weight: 300;
    color: #181f2d;
}
h1, h2, h3, h4 {
    font-weight: 600;
    letter-spacing: 1px;
}

.page-body h1,
.page-body h2,
.page-body h3,
.page-body h4 {
    text-align: left;
}
/** text styles **/
.logo-heading {
    position: relative;
}
.logo-heading::before {
    content:"";
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background-image: url('../assets/img/bull-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
}
h3.logo-heading::before,
h4.logo-heading::before {
    width: 25px;
    height: 25px;
}
.small {
    font-size: 12px;
}
.big {
    font-size: 30px;
}
.light {
    font-weight: 300;
}
.highlight {
    color: #FF0000;
}
.no-margin h1,
.no-margin h2,
.no-margin h3,
.no-margin h4,
.no-margin p {
    margin: 0;
}

/* buttons */
.green-button,
.red-button,
.light-button,
.dark-button,
.blue-button {
    position: relative;
    display: inline-block;
    color: #FFF !important;
    letter-spacing: 1px;
    padding: 10px 20px;
    min-width: 200px;
    max-width: 100%;
    box-sizing: border-box;
    transition: background 0.2s ease-in-out;
    text-decoration: none;
}
.light-button,
.dark-button {
    text-align: left;
    padding: 10px;
    padding-right: 40px;
    background-image: url('../assets/img/right-red.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 95% 10px;
    border: 1px solid #181f2d;
    text-decoration: none;
}
#content a[href$='.pdf'],
.light-button {
    color: #181f2d !important;
    background-color: #FFF;
}
#content a[href$='.pdf'],
.light-button:hover {
    background-color: #f2f2f2;
}
.dark-button {
    background-color: #181f2d;
}
.dark-button:hover {
    background-color: #000;
}
.green-button {
    background: #1aa853;
}
.green-button:hover {
    background: #158442;
}
.red-button {
    background: #ff0000;
}
.red-button:hover {
    background: #cc0202;
}
/* only used for dealer login/out */
.blue-button {
    background: #0000FF;
}
.blue-button:hover {
    background: #0202cc;
}

/* download style */
.download,
#content a[href$='.pdf']{
   position: relative;
   text-decoration: none;
   padding: 10px 20px;
   background-image: none; /* for light and dark buttons */
   padding-left: 0;
}
.download:before,
#content a[href$='.pdf']:before {
    content:"";
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background-image: url('../assets/img/download.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    margin-right: 8px;
}
#content a[href$='.pdf'] {
    border: 1px solid #181f2d;
}
#content a[href$='.pdf']:before {
    margin-left: 4px;
}
/** images */
img {
    max-width: 100% !important;
    height:auto !important;
}
.display-left,
.display-right {
    display: inline-block;
    vertical-align: top;
    width: 60%;
    margin-bottom: 20px;
}
.display-left {
    text-align: left;
    float:left;
    margin-right: 6%;
}
.display-right {
    text-align: right;
    float:right;
    margin-left: 6%;
}
.clear-float {
    clear:both;
}

/* custom blocks */
div > .grey-block:first-of-type {
    margin-top: -40px;
}
.grey-block {
    position: relative;
    background: #f2f2f2;
    padding: 40px 0;
    margin: 40px 0;
    clear: both;
    z-index: 2;
}
.grey-block:before,
.grey-block:after {
    content:"";
    background: #f2f2f2;
    position: absolute;
    top:0;
    bottom:0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.grey-block:before {
    right: 100%;
}
.grey-block:after {
    left: 100%;
}

.two-col {
    display: inline-block;
    vertical-align: top;
    padding: 1% 0;
    padding-bottom: 20px;
    width: 48%;
    box-sizing: border-box;
}
/* nth-of-type ignores class */
article.two-col:nth-of-type(odd){
    padding-right: 3%;
}
article.two-col:nth-of-type(even){
    padding-left: 3%;
}

.circle {
    display: inline-block;
    position: relative;
    width: auto;
    overflow: hidden;
    margin: 1%;
}
.circle::after { /* square */
    content:"";
    width: 200px;
    padding-top: 200px;
}
.circle img {
    position: absolute;
    top:0;
    left:0;
    min-width: 100%;
    height: 100%;
    border-radius: 50%;
}

@media only screen and (max-width: 760px){
    .display-left,
    .display-right {
        width:100%;
        float: none;
        margin: 20px 0;
    }
    .two-col,
    article.two-col:nth-of-type(odd),
    article.two-col:nth-of-type(even){
        width: 100%;
        padding: 1% 0;
    }
    .grey-block {
        padding: 20px 0;
        margin: 20px 0;
    }
}
@media only screen and (max-width: 300px){
    .green-button,
    .red-button,
    .light-button,
    .dark-button,
    .blue-button {
        width: 100%;
        min-width: 0px;
        padding: 10px 5px;
    }
}